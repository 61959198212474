import React, {Component} from "react";
import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Toast} from "react-bootstrap";

export default class ReceiptExchange extends Component {

    constructor(props, context) {
        super(props, context);
        let exchange = props.exchange;
        let state;
        if (exchange == null) {
            state = {
                show: true,
                oldOrder: -1,
                order: 0,
                name: "",
                status: "ACTIVE",
                type: "SEND_ONLY",
                timeOut: 5000,
                exchangeUrl: ""
            };
        } else {
            state = {
                show: true,
                oldOrder: exchange.order,
                order: exchange.order,
                name: exchange.name,
                code: exchange.code,
                status: exchange.status,
                type: exchange.type,
                timeOut: exchange.timeOut,
                exchangeUrl: exchange.exchangeUrl
            };
        }
        state['formValidate'] = false;
        this.state = state;
    }

    close = () => {
        this.setState({show: false})
        this.props.onClose(null);
    }

    showError(message) {
        this.setState({
            errorToast: (
                <Toast role={'alert'}
                       className={'position-absolute'}
                       autohide={true}
                       style={{
                           top: '0px',
                           right: '0px',
                           zIndex: 999
                       }}
                       onClose={() => {
                           this.setState({errorToast: undefined})
                       }}>
                    <Toast.Header className={'bg-danger'}>
                        <strong className="me-auto" style={{color: '#ffffff'}}>Алдаа гарлаа</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            )
        });
    }

    save = (e) => {
        let rMethod = 'POST';
        let rURL = '/rest/exchanges'

        let body = {
            order: this.state.order,
            name: this.state.name,
            code: this.state.code,
            status: this.state.status,
            type: this.state.type,
            timeOut: this.state.timeOut,
            exchangeUrl: this.state.exchangeUrl
        };

        if (this.state.oldOrder >= 0) {
            rURL = rURL + '/' + this.state.oldOrder;
            rMethod = 'PUT';
        }

        const rOpt = {
            method: rMethod,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }
        fetch(rURL, rOpt)
            .then(resp => {
                if (resp.ok) return resp;
                throw resp;
            })
            .then(resp => {
                this.props.onChange();
                this.close();
            })
            .catch(resp => {
                resp.json().then(body => {
                    let message;
                    if (body != null) {
                        message = body.message;
                    } else {
                        message = resp.statusText;
                    }
                    this.showError(message);
                });
            });
        e.preventDefault();
    }

    handleChange = (e) => {
        switch (e.target.name) {
            case 'order':
            case 'timeout':
                this.setState({[e.target.name]: parseInt(e.target.value)});
                break;
            default:
                this.setState({[e.target.name]: e.target.value});
        }
    }

    render() {
        return (
            <Modal size={"lg"} show={this.state.show} onHide={() => this.close()} centered>
                {this.state.errorToast || ''}
                <Form validated={this.state.formValidate} id='exchangeForm' onSubmit={this.save}>
                    <ModalHeader closeButton={true}>Баримтын мэдээлэл солилцох сервис</ModalHeader>
                    <ModalBody>
                        <Form.Label>Order</Form.Label>
                        <Form.Control type={"number"} name={'order'} value={this.state.order} required min={0} onChange={(e) => this.handleChange(e)}/>

                        <Form.Label>Нэр</Form.Label>
                        <Form.Control type={"text"} name={'name'} value={this.state.name} required onChange={(e) => this.handleChange(e)}/>

                        <Form.Label>Code</Form.Label>
                        <Form.Control type={"text"} name={'code'} value={this.state.code} onChange={(e) => this.handleChange(e)}/>

                        <Form.Label>Төрөл</Form.Label>
                        <Form.Select name={'type'} value={this.state.type} required onChange={(e) => this.handleChange(e)}>
                            <option value='SEND_ONLY'>SEND_ONLY</option>
                            <option value='SEND_AND_RECEIVE'>SEND_AND_RECEIVE</option>
                            <option value='PAYMENT'>PAYMENT</option>
                        </Form.Select>

                        <Form.Label>Status</Form.Label>
                        <Form.Select type={"text"} name={'status'} value={this.state.status} required onChange={(e) => this.handleChange(e)}>
                            <option value='ACTIVE'>ACTIVE</option>
                            <option value='INACTIVE'>INACTIVE</option>
                        </Form.Select>

                        <Form.Label>Time Out</Form.Label>
                        <Form.Control type={"text"} name={'timeOut'} value={this.state.timeOut} required min={1000} onChange={(e) => this.handleChange(e)}/>

                        <Form.Label>URL</Form.Label>
                        <Form.Control type={"text"} name={'exchangeUrl'} value={this.state.exchangeUrl} required onChange={(e) => this.handleChange(e)}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button type={"submit"} variant={"danger"}>Хадгалах</Button>
                        <Button variant={"primary"} onClick={() => this.close()}>Хаах</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}