import React, {Component} from "react";
import UserService from "../UserService"
import {Table, Button} from "react-bootstrap";

export default class Operator extends Component {

    state = {
        operators: [],
        operatorError: false,
        status: 'init'
    }

    loadOperators = () => {
        let requestOpt = {
            headers: {
                'Authorization': UserService.getToken()
            }
        };
        fetch('/rest/operators', requestOpt)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw resp;
                }
            })
            .then(body => this.setState({operators: body}))
            .catch(resp => {
                this.setState({
                    operatorError: true
                })
            })


    }

    onAuthenticated = (authenticated) => {
        if (authenticated) {
            UserService.loadUserInfo().then((userInfo) => {

                this.loadOperators();

                this.setState({
                    status: 'loggedIn',
                    name: userInfo.name
                })
            });
        } else {
            this.setState({
                status: 'loggedOut'
            })
        }
    }

    create = (operator) => {
        this.setState({
            status: 'create',
            operator: operator
        });
        let requestOpt = {
            method: 'POST',
            headers: {
                'Authorization': UserService.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({operatorId: operator.accSid})
        }
        fetch('/rest/create', requestOpt).then(resp => {
            if (resp.ok) window.location.reload();
            else this.setState({createProcessError: true});
        })
    }

    componentDidMount () {
        UserService.initKeycloak(this.onAuthenticated);
    }

    render() {
        if (this.state.status === 'loggedIn')
            return (
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col-10'}>
                            <span className={'h5'}>Хэрэглэгч: {this.state.name}</span>
                        </div>
                        <div className={'col-2 text-end'}>
                            <Button variant={'danger'} size={'lg'} onClick={() => UserService.doLogout()}>Гарах</Button>
                        </div>
                    </div>
                    {this.state.operators.length > 0 &&
                        <div>
                            <div className={'mt-3'}>
                                <span>PosAPI идвэхжүүлэх "Хэрэглэгийн систем нийлүүлэгч"-г сонгоно уу."</span>
                            </div>
                            <Table>
                                <thead>
                                <tr>
                                    <th width={'100px'}>ТТД</th>
                                    <th>Нэр</th>
                                    <th width={'100px'}>Сонгох</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.operators.map(operator => (
                                    <tr key={operator.accSid}>
                                        <td>{operator.oprtTin}</td>
                                        <td>{operator.oprtName}</td>
                                        <td>
                                            <Button variant={'primary'} size={'sm'} onClick={() => this.create(operator)}>Тохируулах</Button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    }
                    {this.state.operators.length === 0 && this.state.operatorError === false &&
                        <div className={'text-center text-info'}>
                            Уншиж байна...
                        </div>
                    }
                    {this.state.operatorError === true &&
                        <div className={'text-center'}>
                            <h5 className={'text-danger'}>Таны эрх хүрсэнгүй!!!</h5>
                            <span>Та өөр эрхээр хандана уу.</span>
                        </div>
                    }
                </div>
            );
        else if (this.state.status === 'loggedOut')
            return (
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col-12 text-center'}>
                            <h4>Та PosAPI 3.0 системийн тохируулах хэсэгт хандалаа.</h4>
                            <h5 className={'mt-5'}>Тохиргоо хийхийн тулд "ХЭРЭГЛЭГЧИЙН СИСТЕМ НИЙЛҮҮЛЭГЧИЙН"-ийн эрхээр нэвтрэх шаардлагатай.</h5>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12 text-center'}>
                            <Button variant={'primary'} size={'lg'} onClick={() => UserService.doLogin()}>Нэвтрэх</Button>
                        </div>
                    </div>
                </div>
            )
        else if (this.state.status === 'create') {
            return (
                <div className={'container-fluid text-center mt-5'}>
                    {this.state.createProcessError === true ?
                        <div>
                            <h5 className={'text-danger'}>PosAPI тохируулах процессд алдаа гарлаа.</h5>
                            <Button variant={'secondary'} onClick={() => window.location.reload()}>Ахин ачааллах</Button>
                        </div>
                        :
                        <h5>"{this.state.operator.oprtName}" хэрэглэгчийн систем нийлүүлэгчийн PosAPI-н тохиргоог үүсгэж байна...</h5>
                    }
                </div>
            )
        }
    }
}