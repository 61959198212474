import React, {Component} from "react";
import {Accordion, Badge, Col, ListGroup, Row} from "react-bootstrap";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import AccordionItem from "react-bootstrap/AccordionItem";

export default class PosInfo extends Component {

    state = {
        pos: {
            appInfo: {},
            merchants: [],
        },
        isMedicine: false
    }

    sendData = () => {
        fetch('/rest/sendData').then((result) => {
            this.refreshInfo();
        });
    }

    refreshInfo = () => {
        fetch('/rest/info').then(res => res.json()).then((result) => {
            this.setState({
                pos: result,
                isMedicine: result.condition.isMedicine
            });
        });
    }

    handleCheckboxChange = (event) => {
        this.setState({
            isMedicine: event.target.checked
        });
    };

    saveCondition = () => {
        let body = {
            isMedicine: this.state.isMedicine
        };
        let requestOpt = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }
        fetch('/rest/condition', requestOpt)
            .then(res => {
                this.refreshInfo();
            }).catch(resp => {
        })
    }

    componentDidMount() {
        this.refreshInfo();
    }

    render() {
        return (
            <div className={'container mt-3'}>
                <div className={'card'}>
                    <div className={'card-header'}>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <span className={'h4'}>Ерөнхий мэдээлэл</span>
                            </div>
                            <div className={'col-6 text-end'}>
                                <button type={'button'} className={'btn btn-secondary'} onClick={this.sendData}>Мэдээлэл
                                    илгээх
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <div className={'row'}>
                            <label className="form-label col h6">ПОС-н мэдээлэл:</label>
                        </div>
                        <div className={'row'}>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Id:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.posId || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Дугаар:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.posNo || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Хувилбар:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.version || ''} readOnly={true}/>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <label className="form-label col h6">Оператор байгууллага:</label>
                        </div>
                        <div className={'row'}>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Татвар төлөгчийн
                                    дугаар:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.operatorTIN || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Операторын нэр:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.operatorName || ''} readOnly={true}/>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <label className="form-label col h6">Процессийн мэдэлээлэл:</label>
                        </div>
                        <div className={'row'}>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Нийт сугалаа:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.leftLotteries || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Сүүлд илгээсэн огноо:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.lastSentDate || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Database:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="text" className="form-control form-control-sm"
                                       value={this.state.pos.appInfo['database-host'] || ''} readOnly={true}/>
                            </div>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm">Ажлын хавтас:</label>
                            </div>
                            <div className={'col-4'}>
                                <textarea type="textarea" className="form-control form-control-sm"
                                          value={this.state.pos.appInfo.workDir || ''} readOnly={true}
                                          rows="1"
                                          style={{resize: 'none'}}
                                ></textarea>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <label className="form-label col h6">ПОС-н нөхцөл:</label>
                        </div>
                        <div className={'row'}>
                            <div className={'col-2'}>
                                <label className="form-label col-form-label-sm" htmlFor="flexCheckChecked">Эмийн
                                    сан:</label>
                            </div>
                            <div className={'col-4'}>
                                <input type="checkbox" className="form-check-input" checked={this.state.isMedicine}
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="right"
                                       title="Хадгална уу"
                                       id="flexCheckChecked"
                                       onChange={this.handleCheckboxChange}/>
                            </div>
                            <div className={'col-6'}>
                                <button type="button" class="btn btn-primary btn-sm float-end"
                                        onClick={() => this.saveCondition()}>Хадгалах
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'card mt-3'}>
                    <div className={'card-header h4'}>Мерчантын жагсаалт</div>
                    <div className={'card-body'}>
                        <Accordion>
                            {this.state.pos.merchants.map(merchant => (
                                <AccordionItem eventKey={'mc_' + merchant.tin} key={'mk_' + merchant.tin}>
                                    <AccordionHeader className="p-0">
                                        <Row style={{width: '100%'}}>
                                            <Col md={2}>{merchant.tin}</Col>
                                            <Col md={10}>
                                                <span style={{marginRight: '5px'}}>{merchant.name}</span>
                                                <sup style={{fontSize: '12px'}}>
                                                    <Badge bg="primary" pill>{merchant.customers.length}</Badge>
                                                </sup>
                                            </Col>
                                        </Row>
                                    </AccordionHeader>
                                    <AccordionBody className="p-1">
                                        <ListGroup variant={"flush"}>
                                            {merchant.customers.map(customer => (
                                                <ListGroup.Item eventKey={'cc_' + customer.tin}
                                                                key={'ck_' + customer.tin}>
                                                    <Row>
                                                        <Col className="mx-3" md={2}>{customer.tin}</Col>
                                                        <Col md={3}>
                                                            <span>{customer.name}</span>
                                                        </Col>
                                                        <Col md={5}>
                                                            <Badge bg={customer.vatPayer ? "success" : "danger"}>
                                                                {customer.vatPayer ? 'НӨАТ суутган төлөгч мөн' : 'НӨАТ суутган төлөгч биш'}
                                                            </Badge>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </AccordionBody>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}