import Keycloak from "keycloak-js";

const _kc = new Keycloak('/rest/keycloak.json');

const initKeycloak = (onAuthenticatedCallback) => {
    let redirectUrl = window.location.origin + '/web/silent-check-sso.html';
    if (process.env.NODE_ENV === 'development') {
        redirectUrl = window.location.origin + '/silent-check-sso.html';
    }
    _kc.init({onLoad: 'check-sso', 'checkLoginIframe': false, silentCheckSsoRedirectUri: redirectUrl}).then((authenticated) => {
        if (authenticated) {
            onAuthenticatedCallback(true);
        } else {
            onAuthenticatedCallback(false);
        }
    });
}

const doLogin = _kc.login;
const doLogout = _kc.logout;
const loadUserInfo = _kc.loadUserInfo;
const getToken = () => _kc.token;

const UserService = {
    initKeycloak, doLogin, doLogout, getToken, loadUserInfo
}

export default UserService;