import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import PosInfo from "./general/PosInfo";
import 'bootstrap/dist/css/bootstrap.css'
import {Container, Tab, Tabs} from "react-bootstrap";
import ReceiptExchangeList from "./general/ReceiptExchangeList";
import Operator from "./operator/Operator";
import InvalidReceiptList from "./general/InvalidReceiptList";

class WebPages extends Component {

    state = {
        status: 'init'
    }

    componentDidMount () {
        fetch('/rest/info').then(resp => {
            if (resp.ok) {
                this.setState({status: 'success'})
            } else if (resp.status === 503) {
                this.setState({status: 'generate'})
            } else {
                this.setState({status: 'failure'})
            }
        })
    }

    render() {

        if (this.state.status === 'success') {
            return (
                <Container>
                    <Tabs defaultActiveKey={'generalInfo'}>
                        <Tab eventKey={'generalInfo'} title={'Ерөнхий мэдээлэл'}>
                            <PosInfo/>
                        </Tab>
                        <Tab eventKey={'invalidReceiptList'} title={'Алдаатай баримтын мэдээлэл'}>
                            <InvalidReceiptList/>
                        </Tab>
                        <Tab eventKey={'exchangeList'} title={'Баримтын мэдээлэл солилцох сервис '}>
                            <ReceiptExchangeList/>
                        </Tab>
                    </Tabs>
                </Container>
            );
        } else if (this.state.status === 'generate') {
            return (
                <Container>
                    <Operator/>
                </Container>
            )
        } else if (this.state.status === 'failure') {
            return (
                <div className={'container mt-5'}>
                    <h2 className={'text-danger text-center'}>Unknown Error is occured!!!</h2>
                </div>
            )
        } else {
            return (
                <div className={'container mt-5'}>
                    <h2 className={'text-info text-center'}>Уншиж байна...</h2>
                </div>
            )
        }
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <WebPages/>
    </React.StrictMode>
);
