import React, {Component} from "react";
import {Button, Modal, Toast} from "react-bootstrap";

export default class ReceiptExchangeRemove extends Component {

    constructor(props, context) {
        super(props, context);
        let exchange = props.exchange;
        let state = {
            show: true,
            order: exchange.order,
            name: exchange.name
        };
        this.state = state;
    }

    close = () => {
        this.setState({show: false})
        this.props.onClose(null);
    }

    showError(message) {
        this.setState({
            errorToast: (
                <Toast role={'alert'}
                       className={'position-absolute'}
                       autohide={true}
                       style={{
                           top: '0px',
                           right: '0px',
                           zIndex: 999
                       }}
                       onClose={() => {
                           this.setState({errorToast: undefined})
                       }}>
                    <Toast.Header className={'bg-danger'}>
                        <strong className="me-auto" style={{color: '#ffffff'}}>Алдаа гарлаа</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            )
        });
    }

    save = (e) => {
        let rMethod = 'DELETE';
        let rURL = '/rest/exchanges/' + this.state.order

        console.log('METHOD: ' + rMethod);
        console.log('URL: ' + rURL);

        const rOpt = {
            method: rMethod
        }
        fetch(rURL, rOpt)
            .then(resp => {
                if (resp.ok) return resp;
                throw resp;
            })
            .then(resp => {
                this.props.onChange();
                this.close();
            })
            .catch(resp => {
                resp.json().then(body => {
                    let message;
                    if (body != null) {
                        message = body.message;
                    } else {
                        message = resp.statusText;
                    }
                    this.showError(message);
                });
            });
    }

    handleChange = (e) => {
        switch (e.target.name) {
            case 'order':
            case 'timeout':
                this.setState({[e.target.name]: parseInt(e.target.value)});
                break;
            default:
                this.setState({[e.target.name]: e.target.value});
        }
    }

    render() {
        return (
            <Modal size={"lg"} show={true} centered>
                {this.state.errorToast || ''}
                <Modal.Header>Устгах</Modal.Header>
                <Modal.Body>
                    Та "{this.state.name}" баримтын мэдээлэл солилцох сервисийг устгах даа итгэлтэй байна уу?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={(e) => this.save(e)}>Тийм</Button>
                    <Button variant={"danger"} onClick={() => this.close()}>Үгүй</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}